import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import KcApp from "./Keycloak";
import reportWebVitals from './reportWebVitals';
import { kcContext } from "./Keycloak/context";

import "./index.css";
import Favicon from 'react-favicon';
import { QueryClient, QueryClientProvider } from 'react-query'

export default function App(props) {

  if (kcContext === undefined) {
    throw new Error(
      "This app is a Keycloak theme" +
      "It isn't meant to be deployed outside of Keycloak"
    );
  }

  const [theme, setTheme] = useState({
    "id": "TID000000001",
    "logoUrl": "/html/esmo-res/res/themes/TID000000001/logo_arvato.png",
    "favicon": "/favicon.ico",
    "agbSystemTemplate": "Not defined...",
    "dataPrivacyStatementSystemTemplate": "Not defined...",
    "disclaimerSystemTemplate": "Not defined...",
    "impressumSystemTemplate": "Not defined...",
    "supportSystemTemplate": "Not defined...",
    "themes": "[\"TID000000001\"]"
  });

  return (
    <>
      <Favicon url={process.env.REACT_APP_SXP_URL + theme.favicon} />
      <KcApp
        kcContext={kcContext}
        theme={theme}
        setTheme={setTheme} />
    </>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
