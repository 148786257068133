import { useI18n as useI18nBase } from "keycloakify";

export function useI18n(props) {
    const { kcContext } = props;
    return useI18nBase({
        kcContext,
        "extraMessages": {
            "de": {
                "doLogIn": "Anmelden",
                "doForgotPassword": "Haben Sie Ihr Passwort vergessen?",
                "headSuccess": "Anmeldefehler",
                "emailForgotTitle" : "Passwort zurücksetzen",
                "backToLogin": "« zur Anmeldung",
                "agbSystemTemplate": "Nutzungsbedingungen",
                "dataPrivacyStatementSystemTemplate": "Datenschutzerklärung",
                "disclaimerSystemTemplate": "Disclaimer",
                "impressumSystemTemplate": "Impressum",
                "supportSystemTemplate": "Support"
            },
            "en": {
                "alphanumericalCharsOnly": "Only alphanumerical characters",
                "gender": "Gender",
                "doForgotPassword": "I forgot my password",
                "agbSystemTemplate": "Terms of use",
                "dataPrivacyStatementSystemTemplate": "Privacy policy",
                "disclaimerSystemTemplate": "Disclaimer",
                "impressumSystemTemplate": "Legal notice",
                "supportSystemTemplate": "Support"
            }
        }
    });
}