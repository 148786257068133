import React, { memo } from "react";
import Template from "./Template";
import { FaLock, FaUserAlt } from "react-icons/fa";


const Login = memo((props) => {
    const { kcContext, i18n, theme, doFetchDefaultThemeResources = true, ...kcProps } = props;

    const { social, realm, url, usernameEditDisabled, login, auth, registrationDisabled } = kcContext;

    const { msg, msgStr } = i18n;

    return (
        <Template
            {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
            displayInfo={social.displayInfo}
            displayWide={realm.password && social.providers !== undefined}
            headerNode={msg("doLogIn")}
            theme={props.theme}
            formNode={
                <div id="kc-form">
                    <div
                        id="kc-form-wrapper"
                    >
                        <div className="mb-4">
                            <h1 id="kc-page-title" className="color-default text-[16px] text-default font-sans font-light">{msg("doLogIn")}</h1>
                        </div>
                        {realm.password && (
                            <form id="kc-form-login" action={url.loginAction} method="post">
                                <div className="pb-2 ">
                                    {(() => {
                                        const label = !realm.loginWithEmailAllowed
                                            ? "username"
                                            : realm.registrationEmailAsUsername
                                                ? "email"
                                                : "usernameOrEmail";

                                        const autoCompleteHelper = label === "usernameOrEmail" ? "username" : label;

                                        return (
                                            <div className="w-[320px]">
                                                <input
                                                    tabIndex={1}
                                                    id={autoCompleteHelper}
                                                    placeholder={msgStr("username")}
                                                    className="inline w-[300px] h-[44px] px-3 outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"
                                                    //NOTE: This is used by Google Chrome auto fill so we use it to tell
                                                    //the browser how to pre fill the form but before submit we put it back
                                                    //to username because it is what keycloak expects.
                                                    name={autoCompleteHelper}
                                                    defaultValue={login.username ?? ""}
                                                    type="text"
                                                    {...(usernameEditDisabled
                                                        ? { "disabled": true }
                                                        : {
                                                            "autoFocus": true,
                                                            "autoComplete": "off"
                                                        })}
                                                />
                                                <FaUserAlt className="z-1 text-[#e0e0e0] text-[20px] inline relative right-[30px]" />
                                            </div>
                                        );
                                    })()}
                                </div>
                                <div className="pt-2 pb-5 w-[320px]">
                                    <input
                                        tabIndex={2}
                                        id="password"
                                        placeholder={msgStr("password")}
                                        className="inline w-[300px] h-[44px] px-3  outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"
                                        name="password"
                                        type="password"
                                        autoComplete="off"
                                    />
                                    <FaLock className="z-1 text-[#e0e0e0] text-[20px] inline relative right-[30px]" />
                                </div>
                                <div className="text-right text-default text-[13px] font-light">
                                    <div id="kc-form-options">
                                        {realm.rememberMe && !usernameEditDisabled && (
                                            <div className="checkbox">
                                                <label>
                                                    <input
                                                        tabIndex={3}
                                                        id="rememberMe"
                                                        name="rememberMe"
                                                        type="checkbox"
                                                        {...(login.rememberMe
                                                            ? {
                                                                "checked": true
                                                            }
                                                            : {})}
                                                    />
                                                    {msg("rememberMe")}
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {realm.resetPasswordAllowed && (
                                            <span>
                                                <a tabIndex={5} href={url.loginResetCredentialsUrl}>
                                                    {msg("doForgotPassword")}
                                                </a>
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div id="kc-form-buttons" className="!mt-12">
                                    <input
                                        type="hidden"
                                        id="id-hidden-input"
                                        name="credentialId"
                                        {...(auth?.selectedCredential !== undefined
                                            ? {
                                                "value": auth.selectedCredential
                                            }
                                            : {})}
                                    />
                                    <input
                                        tabIndex={4}
                                        className="h-[44px] text-center w-full bg-buttonPrimary border-buttonSecondary hover:bg-buttonSecondary p-[7px] text-white"
                                        name="login"
                                        id="kc-login"
                                        type="submit"
                                        value={msgStr("doLogIn")}
                                    />
                                </div>
                            </form>
                        )}
                    </div>
                    {realm.password && social.providers !== undefined && (
                        <div id="kc-social-providers" className="">
                            <div className="flex justify-center my-2">
                                ...
                            </div>
                            <div
                                className=""
                            >
                                {social.providers.map(p => ( // h-[44px] text-center w-full bg-buttonPrimary border-buttonSecondary hover:bg-buttonSecondary p-[7px] text-white
                                    <div key={p.providerId} className="text-center border-b-primary text-primary border-b-lg border-b-2 border-neutral border-l-[1px] border-r-[1px] border-t-[1px] w-full  h-[44px] hover:text-buttonSecondary hover:border-b-buttonSecondary hover:border-b-[2px]">
                                        <a href={p.loginUrl} id={`zocial-${p.alias}`} className="flex justify-center items-center m-auto  bg-white w-full h-full hover:no-underline hover:text-buttonSecondary ">
                                            {p.displayName}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            }
            infoNode={
                realm.password &&
                realm.registrationAllowed &&
                !registrationDisabled && (
                    <div id="kc-registration">
                        <span>
                            {msg("noAccount")}
                            <a tabIndex={6} href={url.registrationUrl}>
                                {msg("doRegister")}
                            </a>
                        </span>
                    </div>
                )
            }
        />
    );
});

export default Login;