import React, { useReducer, useEffect, memo, useState } from "react";
import { pathJoin } from "keycloakify/bin/tools/pathJoin";
import { headInsert } from "keycloakify/lib/tools/headInsert";
import { clsx } from "keycloakify/lib/tools/clsx";
import { BsXLg } from "react-icons/bs";
import ReactHtmlParser from 'html-react-parser'
import { useQuery } from 'react-query'
import Color from 'color';
import { useCookies } from 'react-cookie';

const Template = memo((props) => {
    const {
        displayInfo = false,
        displayMessage = true,
        formNode,
        infoNode = null,
        kcContext,
        i18n,
        doFetchDefaultThemeResources,
        setTheme
    } = props;

    const { msg, msgStr } = i18n;
    const { realm, url, message, isAppInitiatedAction } = kcContext;

    const [isExtraCssLoaded, setExtraCssLoaded] = useReducer(() => true, false);
    const [toast, setToast] = useState(null);

    const [cookies, setCookie] = useCookies(['selectedTid']);
    const [selectedTid, setSelectedTid] = useState(cookies.selectedTid || process.env.REACT_APP_TEMPLATE_ID); // Initialwert aus dem Cookie

    const themes = JSON.parse(props.theme.themes);

    const themeURL = process.env.REACT_APP_SXP_URL + "/getSessionParameterMap";

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
            "tid": selectedTid // Dynamischer TID-Wert basierend auf der Auswahl
        })
    };

    const fetcher = () =>
        fetch(themeURL, requestOptions)
            .then((res) => res.json());

    const darkenValue = 0.2;

    useQuery(["theme", selectedTid], fetcher, {
        onSuccess: (data) => {
            const replyData = data.replyObject;
            const primary = !!replyData.mainColorShort ? replyData.mainColorShort : "#0068A9";
            const secondary = !!replyData.mainColorShort ? Color(replyData.mainColorShort).darken(darkenValue) : Color("#0068A9").darken(darkenValue);

            document.documentElement.style.setProperty("--color-primary", primary);
            document.documentElement.style.setProperty("--color-secondary", secondary);

            document.documentElement.style.setProperty("--color-button-primary", !!replyData.esmBackcolorButtons ? replyData.esmBackcolorButtons : primary);
            document.documentElement.style.setProperty("--color-button-secondary", !!replyData.esmBackcolorButtons ? Color(replyData.esmBackcolorButtons).darken(darkenValue) : secondary);
            document.documentElement.style.setProperty("--color-button-text", !!replyData.esmTextcolorButtons ? replyData.esmTextcolorButtons : "#FFFFFF");

            document.documentElement.style.setProperty("--color-message-text", !!replyData.esmTextcolorPanel ? replyData.esmTextcolorPanel : "#000000");
            document.documentElement.style.setProperty("--color-message-header-text", !!replyData.backColorShort ? replyData.backColorShort : "#FFFFFF");

            let logoPosition = "mx-auto";
            if (replyData.logoPosition) {
                if (replyData.logoPosition.toUpperCase() === "RIGHT") {
                    logoPosition = "mr-0 ml-auto";
                } else if (replyData.logoPosition.toUpperCase() === "LEFT") {
                    logoPosition = "ml-0 mr-auto";
                }
            }

            props.setTheme({
                "id": !!replyData.themeShort ? replyData.themeShort : "TID000000001",
                "logoUrl": !!replyData.logoUrl ? replyData.logoUrl : "/html/esmo-res/res/themes/TID000000001/logo_arvato.png",
                "favicon": !!replyData.favIcoUrl ? replyData.favIcoUrl : "/html/esmo-res/res/themes/TID000000001/favicon.ico",
                "logoPosition": logoPosition,
                "agbSystemTemplate": !!replyData.agbSystemTemplate ? replyData.agbSystemTemplate : "Not defined...",
                "dataPrivacyStatementSystemTemplate": !!replyData.dataPrivacyStatementSystemTemplate ? replyData.dataPrivacyStatementSystemTemplate : "Not defined...",
                "disclaimerSystemTemplate": !!replyData.disclaimerSystemTemplate ? replyData.disclaimerSystemTemplate : "Not defined...",
                "impressumSystemTemplate": !!replyData.impressumSystemTemplate ? replyData.impressumSystemTemplate : "Not defined...",
                "supportSystemTemplate": !!replyData.supportSystemTemplate ? replyData.supportSystemTemplate : "Not defined...",
                "themes": replyData.themes
            });
        },
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (!doFetchDefaultThemeResources) {
            setExtraCssLoaded();
            return;
        }

        let isUnmounted = false;
        const cleanups = [];

        const toArr = (x) => (typeof x === "string" ? x.split(" ") : x ?? []);

        Promise.all(
            [
                ...toArr(props.stylesCommon).map(relativePath => pathJoin(url.resourcesCommonPath, relativePath)),
                ...toArr(props.styles).map(relativePath => pathJoin(url.resourcesPath, relativePath))
            ]
                .reverse()
                .map(href =>
                    headInsert({
                        "type": "css",
                        href,
                        "position": "prepend"
                    })
                )
        ).then(() => {
            if (isUnmounted) {
                return;
            }

            setExtraCssLoaded();
        });

        toArr(props.scripts).forEach(relativePath =>
            headInsert({
                "type": "javascript",
                "src": pathJoin(url.resourcesPath, relativePath)
            })
        );

        return () => {
            isUnmounted = true;
            cleanups.forEach(f => f());
        };
    }, [props.kcHtmlClass]);

    if (!isExtraCssLoaded) {
        return null;
    }

    const handleTidChange = (e) => {
        const newTid = e.target.value;
        setSelectedTid(newTid);
        setCookie('selectedTid', newTid);
    };

    return (
        <div className={"px-2.5 pb-2.5 md:pt-32 " + clsx(props.kcLoginClass)}>
            <div className={"mx-auto my-auto p-10 ring-1 ring-[#d0d0d0] w-[350px] h-auto "}>
                <header>
                    <img className={props.theme.logoPosition + " h-[50px] w-min"} src={process.env.REACT_APP_SXP_URL + props.theme.logoUrl} />
                </header>
                <div id="kc-content">
                    <div id="kc-content-wrapper">
                        {/* App-initiated actions should not see warning messages about the need to complete the action during login. */}
                        {displayMessage && message !== undefined && (message.type !== "warning" || !isAppInitiatedAction) && (
                            <div className="bg-[#000000BF] z-50 absolute top-0 h-full left-0 w-full" id="popup-message">
                                <div className="absolute m-auto top-0 bottom-0 left-0 right-0 border-2 border-secondary w-[442px] h-min bg-white dialog-shadow font-sans">
                                    <div className="bg-primary text-messageHeaderText py-[10px] px-[14px] w-full text-[16px] font-normal">
                                        <div >
                                            {message.type === "success" && <span>Success</span>}
                                            {message.type === "warning" && <span>Warning</span>}
                                            {message.type === "error" && <span>Error</span>}
                                            {message.type === "info" && <span>Info</span>}
                                        </div>
                                        <div className="absolute top-0 right-0 mr-6 mt-5 cursor-pointer" onClick={() => document.getElementById("popup-message").style.display = "none"}>
                                            <span className="font-extrabold text-[#FFFFFFDD]"><BsXLg /></span>
                                        </div>
                                    </div>
                                    <div className="pl-20 pt-8 pb-7 text-messageText text-[13px] font-light font-sans">
                                        <ul className="list-disc">
                                            <li>{message.summary}</li>
                                        </ul>
                                    </div>
                                    <div className="bg-[#ececec] p-[6px] flex flex-col items-center" onClick={() => document.getElementById("popup-message").style.display = "none"}>
                                        <button className="h-[32px] text-buttonText bg-buttonPrimary border-buttonSecondary w-[75px] hover:bg-buttonSecondary hover:border-primary">OK</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {toast !== undefined && toast && (
                            <div className="bg-[#000000BF] z-50 absolute top-0 h-full left-0 w-full" id="popup-toast" onClick={() => setToast(null)}>
                                <div className="absolute mx-auto my-8 top-0 bottom-0 left-0 right-0 border-2 border-secondary w-[802px] h-auto bg-white dialog-shadow font-sans">
                                    <div className="bg-primary text-messageHeaderText py-[10px] px-[14px] w-full text-[16px] font-normal">
                                        <div >
                                            <span>{toast.title}</span>
                                        </div>
                                        <div className="absolute top-0 right-0 mr-6 mt-5 cursor-pointer text-[#FFFFFFDD]" onClick={() => setToast(null)}>
                                            <span className="font-extrabold text-[#FFFFFFDD]"><BsXLg /></span>
                                        </div>
                                    </div>
                                    <div className="pl-5 pt-8 pb-7 text-messageText text-[13px] font-sans">
                                        {ReactHtmlParser(ReactHtmlParser(toast.message))}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div id="kc-header" className="!pl-0 !ml-0 mt-12 md:mt-32 text-left w-full text-[22px] mb-8 !text-default font-light">
                            {msg("loginTitleHtml", realm.displayNameHtml)}
                        </div>
                        {formNode}
                        {displayInfo && (<>{infoNode}</>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <div className="mx-auto my-auto p-5 w-min" id="legalcontainer-1017">
                    <div id="legalcontainer-1017-outerCt">
                        <div id="legalcontainer-1017-innerCt">
                            <a className="m-3 text-default cursor-pointer hover:text-default" id="component-1018" href="#" onClick={() => {
                                setToast({
                                    "title": msgStr("agbSystemTemplate"),
                                    "message": props.theme.agbSystemTemplate
                                });
                            }}>
                                {msgStr("agbSystemTemplate")}
                            </a>
                            <a className="m-3 text-default cursor-pointer hover:text-default" id="component-1019" onClick={() => {
                                setToast({
                                    "title": msgStr("impressumSystemTemplate"),
                                    "message": props.theme.impressumSystemTemplate
                                });
                            }}>
                                {msgStr("impressumSystemTemplate")}
                            </a>
                            <a className="m-3 text-default cursor-pointer hover:text-default" id="component-1020" onClick={() => {
                                setToast({
                                    "title": msgStr("disclaimerSystemTemplate"),
                                    "message": props.theme.disclaimerSystemTemplate
                                });
                            }}>
                                {msgStr("disclaimerSystemTemplate")}
                            </a>
                            <a className="m-3 text-default cursor-pointer hover:text-default" id="component-1021" onClick={() => {
                                setToast({
                                    "title": msgStr("dataPrivacyStatementSystemTemplate"),
                                    "message": props.theme.dataPrivacyStatementSystemTemplate
                                });
                            }}>
                                {msgStr("dataPrivacyStatementSystemTemplate")}
                            </a>
                            <a className="m-3 text-default cursor-pointer hover:text-default" id="component-1022" onClick={() => {
                                setToast({
                                    "title": msgStr("supportSystemTemplate"),
                                    "message": props.theme.supportSystemTemplate
                                });
                            }}>
                                {msgStr("supportSystemTemplate")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-auto my-auto p-5 w-min">
                <select
                    id="tidDropdown"
                    value={selectedTid}
                    onChange={handleTidChange}
                    className="w-64 text-[#d0d0d0] border border-[#d0d0d0] rounded p-1"
                >
                    {themes.map((option, index) => (
                        <option key={index} value={option}>
                            Theme: {option}
                        </option>
                    ))}
                </select>
            </div>

        </div>
    );
});

export default Template;